<template>
    <div class="wrapper" v-if="invoice">
        <div class="grid gap-20">
            <div class="invoice-main">
                <div class="item-holder">
                    <div class="logo"><img src="/images/invoice-logo.svg"></div>
                    <h2 class="companyName">99 Freight Truck Dispatch Services</h2>
                </div>

                <div class="item-holder">
                    <div class="name">Invoice total</div>
                    <div class="value invoice-total">${{ CONVERT_PRICE(invoice.total.toFixed(2)) }}</div>
                    <div class="text-overflow status-holder"><div class="status" :class="[invoice.showStatus]">{{ invoice.showStatus }}</div></div>
                </div>

                <div class="item-holder">
                    <div class="name">Bill to</div>
                    <div class="value">
                        <h2 class="companyName">{{ invoice.carrier.companyName }}</h2>
                    </div>
                </div>

                <div class="buttons">
                    <a :href="API_URL + `/dispatch/invoice/${invoiceId}/download`" target="_blank"><Button text="Download PDF" size="small" theme="white"/></a>
                    <a :href="invoice.paymentUrl" v-if="invoice.status === 'pending' && invoice.paymentUrl">
                        <Button text="Pay Invoice" size="small"/>
                    </a>
                </div>

                <div v-if="isLoading(['CreatePaymentLink'])" class="spinner-flex"><Spinner/></div>
            </div>

            <div class="invoice-details">
                <div class="row">
                    <div class="name">Invoice #</div>
                    <div class="value">{{ invoice.invoiceId }}</div>
                </div>
                <div class="row">
                    <div class="name">Date of issue</div>
                    <div class="value">{{ issueDate }}</div>
                </div>
                <div class="row">
                    <div class="name">Date due</div>
                    <div class="value">{{ dueDate }}</div>
                </div>
                <div class="row">
                    <div class="name">Subtotal</div>
                    <div class="value">${{ CONVERT_PRICE(invoice.total.toFixed(2)) }}</div>
                </div>
                <div class="row">
                    <div class="name">Total</div>
                    <div class="value">${{ CONVERT_PRICE(invoice.total.toFixed(2)) }}</div>
                </div>
                <div class="row" v-if="['pending', 'canceled'].includes(invoice.status)">
                    <div class="name">Amount due</div>
                    <div class="value">${{ CONVERT_PRICE(invoice.amountDue.toFixed(2)) }}</div>
                </div>
                <div class="row" v-else>
                    <div class="name">Amount paid</div>
                    <div class="value">${{ CONVERT_PRICE(invoice.amountPaid.toFixed(2)) }}</div>
                </div>
            </div>

        </div>
    </div>
    <div class="page-wrapper" v-else>
        <div v-if="isLoading(['GetInvoiceDetails'])"><Spinner/></div>
        <div v-else><NoDataMessage text="Invoice not found"/></div>
    </div>
</template>

<script>
import moment from 'moment';
    export default {
        data() {
            return {
                invoice: null,
                tableHead: [
                    { name: 'Name' },
                    { name: 'Description' },
                    { name: 'Quantity' },
                    { name: 'Rate' },
                    { name: 'Amount' }
                ],
                columnsSizes: [
                    'minmax(80px, 100px)', // invoice number
                    'minmax(100px, 1fr)', // carrier
                    'minmax(80px, 100px)', // created on
                    'minmax(80px, 120px)', // date status
                    'minmax(80px, 120px)', // due date
                ],
            }
        },
        computed: {
            invoiceId() {
                return this.$route.params.id;
            },
            issueDate() {
                return moment(this.invoice.createdOn).format("MMMM D, YYYY")
            },
            dueDate() {
                return moment(this.invoice.dueDate).format("MMMM D, YYYY")
            }
        },
        methods: {
            payForInvoice() {
                this.ajax('CreatePaymentLink', {
                    url: `/dispatch/invoice/${this.invoiceId}/pay`,
                    method: 'POST',
                    data: {
                        success_url: this.SYSTEM_URL + this.$route.fullPath,
                        cancel_url: this.SYSTEM_URL + this.$route.fullPath,
                    }
                }, (err, body) => {
                    if(err) {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                        return;
                    }
                    if(body.url) {
                        window.location.href = body.url;
                    } else {
                        this.$ShowAlert(body.message || this.TEXT_ERRORS['SOMETHING_WRONG']);
                    }
                });
            },
            getInvoiceDetails() {
                this.ajax('GetInvoiceDetails', {
                    url: `/dispatch/invoice/${this.invoiceId}`,
                    method: 'get'
                }, (err, body) => {
                    if(!err) this.invoice = body;
                });
            }
        },
        mounted() {
            this.getInvoiceDetails();
        }
    }
</script>

<style lang="scss" scoped>

.page-wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.wrapper {
    max-width: 552px;
    margin: 0 auto;
}

.invoice-details {
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    border-radius: 10px;
    background: $themeColor1;
    overflow: hidden;
}

.spinner-flex {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    background: rgba(255,255,255,0.5);
    z-index: 3;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    gap: 30px;
    border-bottom: 1px solid $borderColor;
    &:last-child {
        border: 0;
    }
}

.invoice-main {
    border: 1px solid $borderColor;
    box-shadow: $boxShadow;
    padding: 50px 30px;
    display: grid;
    row-gap: 50px;
    text-align: center;
    justify-content: center;
    justify-items: center;
    border-radius: 10px;
    background: $themeColor1;
    position: relative;
    .buttons {
        display: flex;
        gap: 10px;
    }
}

.invoice-total {
    font-size: 28px;
    font-weight: 500;
}

.logo {
    img {
        max-width: 120px;
        width: 100%;
    }
}

.item-holder {
    display: grid;
    row-gap: 10px;
    justify-content: center;
    justify-items: center;
    .name {
        text-transform: uppercase;
        color: $textLight;
    }
}

.status {
    height: 28px;
    border-radius: 7px;
    transition: ease 0.3s;
    border: 1px solid $themeColor2;
    color: $themeColor2;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    padding: 0 10px 0 20px;
    position: relative;
    font-size: 11px;
    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 7px;
        width: 6px;
        height: 6px;
        background: $themeColor2;
        border-radius: 50%;
        transform: translate(0, -50%);
    }
    &.paid {
        border: 1px solid $success;
        color: $success;
        &::before {
            background: $success;
        }
    }
    &.overdue {
        border: 1px solid $error;
        color: $error;
        &::before {
            background: $error;
        }
    }
    &.canceled {
        border: 1px solid $error;
        color: #fff;
        background: $error;
        &::before {
            background: #fff;
        }
    }
}
</style>